import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const EditProduct = () => {
  const [previewSource, setPreviewSource] = useState("");
  const [previewGallerySource, setPreviewGallerySource] = useState("");
  const [previewGallery1Source, setPreviewGallery1Source] = useState("");
  const [previewGallery2Source, setPreviewGallery2Source] = useState("");
  const [tagName, setTagName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [regPrice, setRegPrice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [saleStatus, setSaleStatus] = useState(false);
  const [saleStartDate, setSaleStartDate] = useState(null);
  const [saleEndDate, setSaleEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [stockQuantity, setStockQuantity] = useState(0);
  const [stockStatus, setStockStatus] = useState("instock");
  const [cost, setCost] = useState();
  const { id } = useParams();
  const [attributes, setAttributes] = useState([]);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [variations, setVariations] = useState([]);
  const [color, setColor] = useState("");
  const [code, setCode] = useState("");
  const [slug, setSlug] = useState("");
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/backendProduct/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setName(data.name);
        setSlug(data.slug);
        setDescription(data.description);
        setShortDescription(data.short_description);
        setRegPrice(data.regular_price);
        setSalePrice(data.sale_price);
        setSaleStatus(data.on_sale);
        setStockQuantity(data.stock_quantity);
        setStockStatus(data.stock_status);
        setSaleStartDate(data.date_on_sale_from);
        setSaleEndDate(data.date_on_sale_to);
        setPreviewSource(data.images[0]?.src);
        setPreviewGallerySource(data.images[1]?.src);
        setVariations(data.variations);
        setAttributes(data.attributes);
        setMetaDescription(data.meta_description);
        data.tags.map((tag) => !tags.includes(tag.name) && tags.push(tag.name));
        data.categories.map(
          (category) =>
            !categories.includes(category.name) &&
            categories.push(category.name)
        );
      });
  }, []);

  // const handleChange = (e) => {
  //   const file = e.target.files[0];
  //   previewFile(file);
  // };
  // const handleChangeGallery = (e) => {
  //   const file = e.target.files[0];
  //   previewGallery(file);
  // };

  // const previewFile = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setPreviewSource(reader.result);
  //   };
  // };
  // const previewGallery = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setPreviewGallerySource(reader.result);
  //   };
  // };

  const handleAddTag = () => {
    tagName && setTags([...tags, tagName]);
    setTagName("");
  };

  const handleRemoveTag = (index) => {
    const rest = tags.filter((tag, i) => i !== index);
    setTags(rest);
  };
  const handleAddCategory = () => {
    categoryName && setCategories([...categories, categoryName]);
    setCategoryName("");
  };

  const handleRemoveCategory = (index) => {
    const rest = categories.filter((tag, i) => i !== index);
    setCategories(rest);
  };

  const handleAddAttribute = () => {
    const newAttribute = {
      name: key,
      options: [value],
    };
    setAttributes([...attributes, newAttribute]);
    setKey("");
    setValue("");
  };
  const handleRemoveAttribute = (index) => {
    const rest = attributes.filter((attribute, i) => i !== index);
    setAttributes(rest);
  };
  const handleAddVariations = () => {
    const newVariations = {
      name: color,
      code: code,
    };
    setVariations([...variations, newVariations]);
    setColor("");
    setCode("");
  };
  const handleRemoveVariations = (index) => {
    const rest = variations.filter((variation, i) => i !== index);
    setVariations(rest);
  };

  const handleUpdateAndPublish = () => {
    const data = {
      name: name,
      slug: slug,
      status: "publish",
      description: description,
      short_description: shortDescription,
      // sku: "",
      price: regPrice,
      regular_price: regPrice,
      sale_price: salePrice,
      date_on_sale_from: saleStartDate,
      date_on_sale_from_gmt: null,
      date_on_sale_to: saleEndDate,
      date_on_sale_to_gmt: null,
      on_sale: JSON.parse(saleStatus),
      // total_sales: 0,
      stock_quantity: stockQuantity,
      // average_rating: "0.00",
      // rating_count: 0,
      categories: categories.map((category) => {
        return {
          name: category,
        };
      }),
      tags: tags.map((tag) => {
        return {
          name: tag,
        };
      }),
      images: [
        {
          src: previewSource,
        },
        {
          src: previewGallerySource,
        },
        {
          src: previewGallery1Source,
        },
        {
          src: previewGallery2Source,
        },
      ],
      attributes: attributes,
      default_attributes: [],
      variations: variations,
      grouped_products: [],
      // "related_ids": [

      // ],
      stock_status: stockStatus,
      cost: cost,
      meta_description: metaDescription,
    };
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/editProduct/${id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Product edited successfully");
  };
  const handleUpdateAndDraft = () => {
    const data = {
      name: name,
      slug: slug,
      status: "draft",
      description: description,
      short_description: shortDescription,
      // sku: "",
      price: regPrice,
      regular_price: regPrice,
      sale_price: salePrice,
      date_on_sale_from: new Date(saleStartDate),
      date_on_sale_from_gmt: null,
      date_on_sale_to: new Date(saleEndDate),
      date_on_sale_to_gmt: null,
      on_sale: JSON.parse(saleStatus),
      // total_sales: 0,
      stock_quantity: stockQuantity,
      // average_rating: "0.00",
      // rating_count: 0,
      categories: categories.map((category) => {
        return {
          name: category,
        };
      }),
      tags: tags.map((tag) => {
        return {
          name: tag,
        };
      }),
      images: [
        {
          src: previewSource,
        },
        {
          src: previewGallerySource || "",
        },
        {
          src: previewGallery1Source || "",
        },
        {
          src: previewGallery2Source || "",
        },
      ],
      attributes: attributes,
      default_attributes: [],
      variations: variations,
      grouped_products: [],
      // "related_ids": [

      // ],
      stock_status: stockStatus,
      cost: cost,
      meta_description: metaDescription,
    };
    fetch(
      `https://flormar-web-server-nayem55-flormar-bd.vercel.app/editProduct/${id}`,
      {
        method: "put",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
    toast.success("Product edited successfully");
  };

  return (
    <div className="mx-12 flex mb-20 ">
      <div className="w-[70%]">
        <h1 className="mt-12 mr-12 text-2xl ">Edit Product</h1>
        <input
          type="text"
          placeholder="Product name"
          className="w-[100%] px-3 mt-10 border border-secondary py-1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Slug Name</p>
        <input
          type="text"
          placeholder="Slug name"
          className="w-[100%] px-3 border border-secondary py-1"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Product description</p>
        <Editor
          apiKey="h86rpxhf2vvrathsd6ctr82yckmjy5roq36005saoeq81uul" // Replace with your TinyMCE API key
          value={description}
          init={{
            height: 300,
            menubar: false,
            plugins: "link image code",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code",
          }}
          onEditorChange={(newValue) => setDescription(newValue)}
        />
        <p className="mt-10 text-xl mb-6">Regular price (TK.)</p>
        <input
          type="text"
          className="w-[100%] px-3 border border-secondary py-1"
          value={regPrice}
          onChange={(e) => setRegPrice(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Sale price (TK.)</p>
        <input
          type="text"
          className="w-[100%] px-3 border border-secondary py-1"
          value={salePrice}
          onChange={(e) => setSalePrice(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Sale status</p>
        <select
          type="text"
          className="w-[100%] px-3 border border-secondary py-2"
          onChange={(e) => setSaleStatus(e.target.value)}
        >
          <option>
            {JSON.stringify(saleStatus) === "true" ? "true" : "false"}
          </option>
          <option>
            {!(JSON.stringify(saleStatus) === "true") ? "true" : "false"}
          </option>
        </select>
        <p className="mt-10 text-xl mb-6">Sale start date</p>
        <input
          type="date"
          className="w-[100%] px-3 border border-secondary py-1"
          value={saleStartDate}
          onChange={(e) => setSaleStartDate(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Sale end date</p>
        <input
          type="date"
          className="w-[100%] px-3 border border-secondary py-1"
          value={saleEndDate}
          onChange={(e) => setSaleEndDate(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Cost of Good</p>
        <input
          type="text"
          className="w-[100%] px-3  border border-secondary py-1"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Product short description</p>
        <Editor
          apiKey="h86rpxhf2vvrathsd6ctr82yckmjy5roq36005saoeq81uul" // Replace with your TinyMCE API key
          value={shortDescription}
          init={{
            height: 300,
            menubar: false,
            plugins: "link image code",
            toolbar:
              "undo redo | bold italic | alignleft aligncenter alignright | code",
          }}
          onEditorChange={(newValue) => setShortDescription(newValue)}
        />
        <p className="mt-10 text-xl mb-6">Product meta description</p>
        <textarea
          rows={10}
          className="w-[100%] px-3 border border-secondary py-1"
          value={metaDescription}
          onChange={(e) => setMetaDescription(e.target.value)}
        ></textarea>
        <p className="mt-10 text-xl mb-6">Stock quantity</p>
        <input
          type="text"
          className="w-[100%] px-3  border border-secondary py-1"
          value={stockQuantity}
          onChange={(e) => setStockQuantity(e.target.value)}
        ></input>
        <p className="mt-10 text-xl mb-6">Stock status</p>
        <select
          type="text"
          className="w-[100%] px-4  border border-secondary py-2"
          onChange={(e) => setStockStatus(e.target.value)}
        >
          <option></option>
          <option>instock</option>
          <option>outofstock</option>
        </select>
      </div>
      <div className="w-[30%] mt-12">
        <div className="flex gap-10 ml-10 mt-[70px]">
          <button
            onClick={handleUpdateAndDraft}
            className="border border-accent text-accent flex justify-center items-center h-[35px] w-[160px] font-bold ease-in-out duration-300 hover:bg-accent hover:text-primary hover:border-none"
          >
            Save Draft
          </button>
          <button
            onClick={handleUpdateAndPublish}
            className="border border-accent flex justify-center items-center h-[35px] w-[160px] ease-in-out duration-300 bg-accent text-primary font-bold  hover:bg-primary hover:text-accent"
          >
            Publish
          </button>
        </div>
        <div className="ml-10 mt-[50px] text-xl">
          <p className="mb-6">Product Image</p>
          <div>
            {previewSource && (
              <img className="h-[400px]" src={previewSource} alt=""></img>
            )}
          </div>
          {/* <input onChange={handleChange} type="file"></input> */}
          <input
            onChange={(e) => setPreviewSource(e.target.value)}
            placeholder="Enter image url"
            type="text"
            className="w-[84%] px-3  border border-secondary py-1"
          ></input>
          <p className="mb-6 mt-10">Product Gallery</p>
          <div>
            {previewSource && (
              <div className="flex gap-6">
                <img
                  className="w-[100px] mb-4"
                  src={previewGallerySource}
                  alt=""
                ></img>
                <img
                  className="w-[100px] mb-4"
                  src={previewGallery1Source}
                  alt=""
                ></img>
                <img
                  className="w-[100px] mb-4"
                  src={previewGallery2Source}
                  alt=""
                ></img>
              </div>
            )}
          </div>
          {/* <input onChange={handleChangeGallery} type="file"></input> */}
          <input
            onChange={(e) => setPreviewGallerySource(e.target.value)}
            placeholder="Enter image url"
            type="text"
            className="w-[84%] px-3 mb-4 border border-secondary py-1"
          ></input>
          <input
            onChange={(e) => setPreviewGallery1Source(e.target.value)}
            placeholder="Enter image url"
            type="text"
            className="w-[84%] px-3 mb-4 border border-secondary py-1"
          ></input>
          <input
            onChange={(e) => setPreviewGallery2Source(e.target.value)}
            placeholder="Enter image url"
            type="text"
            className="w-[84%] px-3 mb-4 border border-secondary py-1"
          ></input>
          <div>
            <p className="mt-10 mb-6">Product Tags</p>
            <div className="flex items-center">
              <input
                onChange={(e) => setTagName(e.target.value)}
                value={tagName}
                type="text"
                className="border border-secondary px-2 w-[60%]"
              ></input>
              <button
                onClick={handleAddTag}
                className="border border-accent bg-accent text-primary w-[20%] px-3 text-sm flex justify-center items-center pt-[6px] pb-[3px] ml-4 ease-in-out duration-300 hover:bg-primary hover:text-accent"
              >
                Add
              </button>
            </div>
          </div>
          <div className="flex gap-4 flex-wrap my-4">
            {tags.map((tag, i) => (
              <div className="border border-accent px-4 pb-1 pt-2  flex justify-between items-center gap-4 ">
                <p className="text-sm">{tag}</p>
                <button className="text-sm" onClick={() => handleRemoveTag(i)}>
                  X
                </button>
              </div>
            ))}
          </div>
          <div>
            <p className="mt-6 mb-6">Product Categories</p>
            <div className="flex items-center">
              <input
                onChange={(e) => setCategoryName(e.target.value)}
                value={categoryName}
                type="text"
                className="border border-secondary px-2 w-[60%]"
              ></input>
              <button
                onClick={handleAddCategory}
                className="border border-accent bg-accent text-primary w-[20%] px-3 text-sm flex justify-center items-center pt-[6px] pb-[3px] ml-4 ease-in-out duration-300 hover:bg-primary hover:text-accent"
              >
                Add
              </button>
            </div>
          </div>
          <div className="flex gap-4 flex-wrap my-4">
            {categories.map((category, i) => (
              <div className="border border-accent px-4 pb-1 pt-2  flex justify-between items-center gap-4 ">
                <p className="text-sm">{category}</p>
                <button
                  className="text-sm"
                  onClick={() => handleRemoveCategory(i)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div>
            <p>Add Attributes</p>
            <div className="flex gap-4">
              <input
                onChange={(e) => setKey(e.target.value)}
                value={key}
                type="text"
                placeholder="Key"
                className="border border-secondary px-2 mt-4 w-[20%]"
              ></input>
              <input
                onChange={(e) => setValue(e.target.value)}
                value={value}
                type="text"
                className="border border-secondary px-2 mt-4 w-[20%] "
                placeholder="Value"
              ></input>
              <button
                onClick={handleAddAttribute}
                className="border border-accent bg-accent text-primary w-[20%] px-3 text-sm flex justify-center items-center pt-[6px] pb-[3px] mt-4 ml-4 ease-in-out duration-300 hover:bg-primary hover:text-accent"
              >
                Add
              </button>
            </div>
          </div>
          <div>
            {attributes.map((attribute, i) => (
              <div className="pb-1 pt-2 mt-4 w-[60%] flex justify-between items-center gap-4 ">
                <div className="flex gap-4">
                  <p className="text-sm border border-accent px-6 py-1 ">
                    {attribute?.name}
                  </p>
                  <p className="text-sm border border-accent px-6 py-1 ">
                    {" "}
                    {attribute?.options && attribute?.options[0]}
                  </p>
                </div>
                <button
                  className="text-sm"
                  onClick={() => handleRemoveAttribute(i)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className="mt-6">
            <p>Add Variations</p>
            <div className="flex gap-4">
              <input
                onChange={(e) => setColor(e.target.value)}
                value={color}
                type="text"
                placeholder="color"
                className="border border-secondary px-2 mt-4 w-[20%]"
              ></input>
              <input
                onChange={(e) => setCode(e.target.value)}
                value={code}
                type="text"
                className="border border-secondary px-2 mt-4 w-[20%] "
                placeholder="code"
              ></input>
              <button
                onClick={handleAddVariations}
                className="border border-accent bg-accent text-primary w-[20%] px-3 text-sm flex justify-center items-center pt-[6px] pb-[3px] mt-4 ml-4 ease-in-out duration-300 hover:bg-primary hover:text-accent"
              >
                Add
              </button>
            </div>
          </div>
          <div>
            {variations.map((variation, i) => (
              <div className="pb-1 pt-2 mt-4 w-[60%] flex justify-between items-center gap-4 ">
                <div className="flex gap-4">
                  <p className="text-sm border border-accent px-6 py-1 ">
                    {variation?.color}
                  </p>
                  <p className="text-sm border border-accent px-6 py-1 ">
                    {" "}
                    {variation?.code}
                  </p>
                </div>
                <button
                  className="text-sm"
                  onClick={() => handleRemoveVariations(i)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
