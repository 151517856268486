import lgBanner1 from "../../Images/HQ Banner1.png";
import lgBanner2 from "../../Images/clearance banner-pc.png";
import lgBanner3 from "../../Images/super september-pc.jpg";
import lgBanner5 from "../../Images/full-color-Nail-Polish-slider-buy-1-get-2.webp";
import lgBanner8 from "../../Images/HQ banner2.png";
import lgBanner9 from "../../Images/Voucher Slider PC.jpg";
import lgBanner10 from "../../Images/Day Cream  web banner pc.png";
import mbBanner1 from "../../Images/mb-HQ Banner1.png";
import mbBanner2 from "../../Images/clearance banner.png";
import mbBanner3 from "../../Images/Super September-mb.png";
import mbBanner5 from "../../Images/Nail-Polish-slider-buy-1-get-1-Mobile.png";
import mbBanner8 from "../../Images/mb-HQ banner2.png";
import mbBanner9 from "../../Images/Voucher Slider mobile.jpg";
import mbBanner10 from "../../Images/Day Cream  web banner mobile.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "./Banner.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import dayjs from "dayjs";

const Banner = () => {
  const { searchText, setSearchText } = useContext(ThemeContext);
  const banner01 = window.innerWidth > 992 ? lgBanner1 : mbBanner1;
  const banner02 = window.innerWidth > 992 ? lgBanner2 : mbBanner2;
  const banner03 =
    window.innerWidth > 992
      ? "https://luvit.com.bd/wp-content/uploads/2024/09/WebSite-slider-Pc--1300x339.jpg"
      : "https://luvit.com.bd/wp-content/uploads/2024/09/Website-Slider-Mobile--638x800.jpg";
  const banner05 = window.innerWidth > 992 ? lgBanner5 : mbBanner5;
  const banner08 = window.innerWidth > 992 ? lgBanner8 : mbBanner8;
  const banner09 = window.innerWidth > 992 ? lgBanner9 : mbBanner9;
  const banner10 = window.innerWidth > 992 ? lgBanner10 : mbBanner10;
  const navigate = useNavigate();

  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2024-10-03 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2024-10-13 23:59:00", "Asia/Dhaka");

  const offerValidity =
    currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime);
  console.log(offerValidity);

  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        navigation={window.innerWidth < 992 ? false : true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={window.innerWidth < 700 ? false : { dynamicBullets: true }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper h-auto"
      >
        {offerValidity && (
          <SwiperSlide>
            <Link to="/shop">
              <img
                className="w-[93vw] rounded-2xl mx-auto h-auto"
                src={banner09}
                alt="sale"
              />
            </Link>
          </SwiperSlide>
        )}

        <SwiperSlide>
          <Link to="/search/nail%20enamel">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner03}
              alt="Eid sale"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/search/day cream">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner10}
              alt="sale"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link to="/clearance-Offer">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner02}
              alt="Offers"
            />
          </Link>
        </SwiperSlide>

        {/* <SwiperSlide>
          <Link to="/shop">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner01}
              alt="Best Deals"
            />
          </Link>
        </SwiperSlide> */}

        {/* <SwiperSlide>
          <Link to="/shop">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner08}
              alt="Best Deals"
            />
          </Link>
        </SwiperSlide> */}

        {/* <SwiperSlide>
          <Link to="/search/full color nail enamel">
            <img
              className="w-[93vw] rounded-2xl mx-auto h-auto"
              src={banner05}
              alt="Best nail polish"
            />
          </Link>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default Banner;
